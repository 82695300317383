import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";

import TaskCard from "@/components/dashboard/TaskCard";
import Layout from "@components/Layout";
import { useAuthContext } from "@/context/AuthContext";
import Modal from "@/components/Modal";

// TODO:
//  - on launch task, check if user is still assigned & task is
//  - after user logs out, tasks done by user should be removed
//    - use localStorage to store done tasks every session
//    - change fetch task to only get tasks that are pending or ongoing

// const user = {
//   user_id: "1",
//   email: "agent@cybersoftbpo.com",
//   first_name: "Agent",
//   last_name: "Tester",
//   role: "agent",
//   created_at: "2024-09-13T10:44:24.854Z",
// };

export default function Home() {
  const { user } = useAuthContext();

  const [tasks, setTasks] = useState([]);

  // Use useEffect to check for localStorage on the client-side
  const previousEtag = useMemo(() => {
    if (typeof window !== "undefined") {
      // Check if we are in a browser environment
      return localStorage.getItem("tasksEtag");
    }
    return null; // Return null during server-side rendering
  }, [tasks]);

  const getTasks = useCallback(async () => {
    console.log("Fetching tasks...");

    if (user) {
      try {
        console.log("Tasks", tasks);

        // Only send the ETag if tasks are already in the state (check for an existing state)
        const shouldUseCache = tasks && tasks.length > 0;

        if (shouldUseCache) console.log("Using cache...");

        const response = await fetch(
          `/api/users/${user.user_id}/tasks?role=${user.role}`,
          {
            method: "GET",
            headers: {
              "If-None-Match": shouldUseCache ? previousEtag || "" : "",
            },
          }
        );

        if (response.status === 304) {
          console.log("No new tasks");
          return;
        }

        const tempTasks = (await response.json()) || [];

        // Store the new ETag in localStorage
        const etag = response.headers.get("ETag");
        if (etag) {
          localStorage.setItem("tasksEtag", etag);
        }

        // Sort and update tasks in the state
        const sortedTasks = tempTasks.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );

        console.log("Fetched tasks", sortedTasks);

        setTasks(sortedTasks); // Update your state with the new tasks
      } catch (error) {
        console.error(error);
      }
    }
  }, [previousEtag, user]); // useCallback dependencies

  useEffect(() => {
    getTasks();

    const interval = setInterval(() => {
      getTasks();
    }, 20000);

    return () => clearInterval(interval);
  }, [getTasks]);

  const [isClickPopupVisible, setIsClickPopupVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);

  const handleClick = (task) => {
    setSelectedTask(task);
    setIsClickPopupVisible(true);
  };

  const closeClickPopup = () => {
    setIsClickPopupVisible(false);
  };

  const [agentOptions, setAgentOptions] = useState([]);

  useEffect(() => {
    if (user && user.role === "manager") {
      fetch(`/api/users?role=agent,manager,supervisor`)
        .then((res) => res.json())
        .then((data) => {
          setAgentOptions(
            data
              .sort((a, b) => {
                // sort by last_name
                if (a.last_name < b.last_name) return -1;
                if (a.last_name > b.last_name) return 1;
                return 0;
              })
              .map((agent) => ({
                value: agent.user_id,
                label: `${agent.last_name}, ${agent.first_name}`,
                data: agent,
              }))
          );
        });
    }
  }, [user]);

  const [adminView, setAdminView] = useState(false);

  const [editTaskId, setEditTaskId] = useState(null);
  const [editedTask, setEditedTask] = useState(null);
  const [editTaskLoading, setEditTaskLoading] = useState(false);

  useEffect(() => {
    if (editTaskId) {
      setEditedTask(tasks.find((task) => task.task_id === editTaskId));
    } else {
      setEditedTask(null);
    }
  }, [editTaskId]);

  const editTask = async (task) => {
    setEditTaskLoading(true);

    try {
      const response = await fetch(`/api/tasks/${task.task_id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: task.user_id,
        }),
      });

      if (response.ok) {
        const updatedTask = await response.json();

        setTasks((prev) =>
          prev.map((t) =>
            t.task_id === updatedTask.task_id
              ? {
                  ...task,
                  status: "pending",
                }
              : t
          )
        );
      }

      setEditTaskId(null);
    } catch (error) {
      console.error(error);
      const logId = Date.now();
      alert(`An error occurred. Please try again. Log ID: ${logId}`);

      fetch("/api/logger", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          `[LOG ID: ${logId}] ${error.stack || error.message}`
        ),
      });
    }

    setEditTaskLoading(false);
  };

  return (
    <Layout>
      <Modal hidden={!editTaskId} maxHeight="90dvh" width="30dvw">
        {editedTask ? (
          <div className="flex flex-col gap-4 justify-center w-full">
            <div className="text-left flex flex-col gap-2">
              <p className="font-semibold text-lg">Task {editedTask.task_id}</p>

              <p className="font-semibold">
                Task Type:{" "}
                <span className="font-normal">{editedTask.type}</span>
              </p>

              {editedTask.group?.tm_task_id ? (
                <p className="font-semibold">
                  TM Task:{" "}
                  <span className="font-normal">
                    {editedTask.group?.tm_task_id}
                  </span>
                </p>
              ) : null}

              <p className="font-semibold">
                Created:{" "}
                <span className="font-normal">
                  {new Date(editedTask.created_at).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </span>
              </p>

              <div className="flex gap-2 items-center">
                <p className="font-semibold">Agent: </p>

                <Select
                  className="z-50"
                  isSearchable
                  options={agentOptions}
                  value={
                    editedTask.user &&
                    agentOptions.find(
                      (agent) => agent.data.user_id === editedTask.user.user_id
                    )
                  }
                  onChange={(selectedOption) => {
                    setEditedTask((prev) => ({
                      ...prev,
                      user: selectedOption.data,
                      user_id: selectedOption.value,
                    }));
                  }}
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#9CA3AF",
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: "200px",
                    }),
                  }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "#F5F5F5",
                      primary: "#0C3C60",
                    },
                  })}
                />
              </div>
            </div>

            <div className="bg-gray-200 h-0.5 w-full" />

            <div
              className={`flex gap-4 w-full justify-center ${
                editTaskLoading ? "animate-pulse cursor-not-allowed" : ""
              }`}
            >
              <button
                className={`w-fit rounded-md px-4 py-1 border border-dark-blue text-dark-blue cursor-pointer`}
                onClick={() => {
                  setEditTaskId(null);
                }}
              >
                Close
              </button>

              <button
                className={`w-fit rounded-md px-4 py-1 bg-dark-blue text-white cursor-pointer disabled:cursor-not-allowed disabled:opacity-50`}
                disabled={
                  tasks.find((task) => task.task_id === editTaskId)?.user_id ===
                  editedTask.user_id
                }
                onClick={() => {
                  if (!editTaskLoading) editTask(editedTask);
                }}
              >
                Save
              </button>
            </div>
          </div>
        ) : null}
      </Modal>

      <div className="flex flex-col gap-3 h-full">
        {/* Header Row */}
        <div className="flex justify-between items-center">
          <div className="flex flex-row gap-1 items-center text-center">
            <p className="text-xl font-large">Active Tasks</p>
            <p className="text-slate-800 font-medium w-5 text-center rounded-full">
              {
                tasks.filter((task) =>
                  adminView ? true : task.user_id === user.user_id
                ).length
              }
            </p>
          </div>

          {(user?.role === "manager" || user?.role === "supervisor") && (
            <label className="z-20 bg-white rounded-md inline-flex items-center cursor-pointer px-2 py-1">
              <input
                type="checkbox"
                checked={adminView}
                onChange={() => {
                  setAdminView((prev) => !prev);
                }}
                className="sr-only peer"
              />
              <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-dark-blue"></div>
              <span className="ms-3 text-sm text-grey-100 pr-2 capitalize">
                {user.role} View
              </span>
            </label>
          )}
        </div>

        {/* Main Content */}
        <div className="flex flex-row grow gap-2">
          {/* PENDING */}
          <div className="flex flex-col bg-white rounded-md h-full w-1/3">
            {/* Header Row */}
            <div className="px-4 py-1.5 items-center rounded-t-md justify-between my-2">
              <div className="flex gap-2 items-center">
                <p className="text-slate-800 text-lg font-medium">
                  Not Started
                </p>
                <p className="text-slate-800 text-sm font-medium px-2 text-center bg-slate-300 rounded-full">
                  {
                    tasks
                      .filter((task) => task.status === "pending")
                      .filter((task) =>
                        adminView ? true : task.user_id === user.user_id
                      ).length
                  }
                </p>
              </div>
              <div className="bg-gray-500 h-0.5 w-full mt-2"></div>
            </div>

            {/* Content */}
            <div className="p-4 flex flex-col gap-4 h-[82dvh] overflow-y-auto">
              {tasks
                .filter((task) => task.status === "pending")
                .filter((task) =>
                  adminView ? true : task.user_id === user.user_id
                )
                .map((task, index) => (
                  <TaskCard
                    onClick={() => handleClick(task)}
                    key={index}
                    task={task}
                    user={task.user || user}
                    disabled={
                      // disable if user has at least 1 ongoing task
                      (user.role === "agent" &&
                        tasks
                          .filter((task) => task.status === "ongoing")
                          .filter((task) =>
                            adminView ? true : task.user_id === user.user_id
                          ).length > 0) ||
                      (user.role === "supervisor" &&
                        task.user_id !== user.user_id)
                    }
                    editable={user.role === "manager"}
                    setEditTaskId={setEditTaskId}
                  />
                ))}
            </div>
          </div>

          {/* ONGOING */}
          <div className="flex flex-col bg-white rounded-md h-full w-1/3">
            {/* Header Row */}
            <div className="px-4 py-1.5 items-center rounded-t-md justify-between my-2">
              <div className="flex gap-2 items-center">
                <p className="text-slate-800 text-lg font-medium">Ongoing</p>
                <p className="text-slate-800 text-sm font-medium px-2 text-center bg-slate-300 rounded-full">
                  {
                    tasks
                      .filter((task) => task.status === "ongoing")
                      .filter((task) =>
                        adminView ? true : task.user_id === user.user_id
                      ).length
                  }
                </p>
              </div>
              <div className="bg-gray-500 h-0.5 w-full mt-2"></div>
            </div>

            {/* Content */}
            <div className="p-4 flex flex-col gap-4 h-[82dvh] overflow-y-auto">
              {tasks
                .filter((task) => task.status === "ongoing")
                .filter((task) =>
                  adminView ? true : task.user_id === user.user_id
                )
                .map((task, index) => (
                  <TaskCard
                    onClick={() => handleClick(task)}
                    key={index}
                    task={task}
                    user={task.user || user}
                    editable={user.role === "manager"}
                    setEditTaskId={setEditTaskId}
                    disabled={
                      user.role === "supervisor" &&
                      task.user_id !== user.user_id
                    }
                  />
                ))}
            </div>
          </div>

          {/* DONE */}
          <div className="flex flex-col bg-white rounded-md h-full w-1/3">
            {/* Header Row */}
            <div className="px-4 py-1.5 items-center rounded-t-md justify-between my-2">
              <div className="flex gap-2 items-center">
                <p className="text-slate-800 text-lg font-medium">Done</p>
                <p className="text-slate-800 text-sm font-medium px-2 text-center bg-slate-300 rounded-full">
                  {
                    tasks
                      .filter((task) => task.status === "success")
                      .filter((task) =>
                        adminView ? true : task.user_id === user.user_id
                      ).length
                  }
                </p>
              </div>
              <div className="bg-gray-500 h-0.5 w-full mt-2"></div>
            </div>

            {/* Content */}
            <div className="p-4 flex flex-col gap-4 h-[82dvh] overflow-y-auto">
              {tasks
                .filter((task) => task.status === "success")
                .filter((task) =>
                  adminView ? true : task.user_id === user.user_id
                )
                .map((task, index) => (
                  <TaskCard
                    onClick={() => handleClick(task)}
                    key={index}
                    task={task}
                    user={task.user || user}
                    setEditTaskId={setEditTaskId}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
