import Link from "next/link";
import { Item, Menu, useContextMenu } from "react-contexify";

const TaskCard = ({
  onClick,
  task,
  user,
  disabled,
  editable,
  setEditTaskId,
}) => {
  const CTX_MENU_ID = `task-context-menu-${task.task_id}`;

  const { show } = useContextMenu({
    id: CTX_MENU_ID,
  });

  const handleContextMenu = (e, taskId) => {
    e.preventDefault();

    show({
      event: e,
      props: {
        taskId,
      },
    });
  };

  return (
    <>
      <Menu
        id={CTX_MENU_ID}
        animation={{ enter: "slide", exit: "slide" }}
        className="ctx-menu"
      >
        <Item
          id="Edit"
          onClick={() => {
            setEditTaskId(task.task_id);
          }}
        >
          Edit
        </Item>
      </Menu>

      <div
        onClick={onClick}
        className="bg-gray-100 rounded-md drop-shadow-md py-2 px-4 flex flex-col gap-2"
        onContextMenu={(e) => {
          if (editable) handleContextMenu(e, task.task_id);
        }}
      >
        <div className="flex justify-between">
          <p className="font-semibold">Task {task.task_id}</p>

          {/* TODO: Priority */}
          <div>
            <div
              className={`text-sm rounded-full px-2 w-fit ${
                task.type === "splitter"
                  ? "bg-[#EABCF9]/40 text-[#78007D]"
                  : task.type === "indexing"
                  ? "bg-[#67D7F7]/40 text-[#005F9B]"
                  : "bg-[#75DAB0]/40 text-[#00693B]"
              }`}
            >
              {task.type}
            </div>
          </div>
        </div>

        <div className="flex gap-2 text-sm text-gray-600">
          {task.group?.tm_task_id ? `TM Task: ${task.group?.tm_task_id}` : ""}
        </div>

        <div className="flex gap-2 items-center text-xs text-gray-600">
          <svg
            className="align-middle"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_64_2456)">
              <path
                d="M7.99998 4.00028V8.00029L10.6666 9.33362M14.6666 8.00029C14.6666 11.6822 11.6819 14.667 7.99998 14.667C4.31808 14.667 1.33331 11.6822 1.33331 8.00029C1.33331 4.31839 4.31808 1.33362 7.99998 1.33362C11.6819 1.33362 14.6666 4.31839 14.6666 8.00029Z"
                stroke="#6C757D"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_64_2456">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0 0.000244141)"
                />
              </clipPath>
            </defs>
          </svg>

          <p>
            {new Date(task.created_at).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
            })}
          </p>
        </div>

        <div className="bg-gray-200 h-0.5 w-full"></div>

        <div className="flex justify-between">
          {/* TODO: if agent view, don't show agent name */}
          <div className="flex items-center gap-2 text-sm">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2.00024C6.477 2.00024 2 6.47724 2 12.0002C2 17.5232 6.477 22.0002 12 22.0002C17.523 22.0002 22 17.5232 22 12.0002C22 6.47724 17.523 2.00024 12 2.00024ZM8.5 9.50024C8.5 9.04062 8.59053 8.58549 8.76642 8.16085C8.94231 7.73621 9.20012 7.35037 9.52513 7.02537C9.85013 6.70036 10.236 6.44256 10.6606 6.26667C11.0852 6.09077 11.5404 6.00024 12 6.00024C12.4596 6.00024 12.9148 6.09077 13.3394 6.26667C13.764 6.44256 14.1499 6.70036 14.4749 7.02537C14.7999 7.35037 15.0577 7.73621 15.2336 8.16085C15.4095 8.58549 15.5 9.04062 15.5 9.50024C15.5 10.4285 15.1312 11.3187 14.4749 11.9751C13.8185 12.6315 12.9283 13.0002 12 13.0002C11.0717 13.0002 10.1815 12.6315 9.52513 11.9751C8.86875 11.3187 8.5 10.4285 8.5 9.50024ZM18.258 16.9842C17.5092 17.9256 16.5575 18.6857 15.4739 19.2079C14.3904 19.7302 13.2029 20.001 12 20.0002C10.7971 20.001 9.60965 19.7302 8.52607 19.2079C7.44249 18.6857 6.49081 17.9256 5.742 16.9842C7.363 15.8212 9.575 15.0002 12 15.0002C14.425 15.0002 16.637 15.8212 18.258 16.9842Z"
                fill="#475467"
              />
            </svg>

            <p className="font-light">
              {user.first_name} {user.last_name}
            </p>
          </div>

          {task.status === "success" || disabled ? null : (
            <Link href={`/${task.type}/${task.task_id}`} className="text-2xl">
              <svg
                width="30"
                height="22"
                viewBox="0 0 30 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.25 11L23.75 11M23.75 11L15 4.58335M23.75 11L15 17.4167"
                  stroke="#134074"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default TaskCard;
